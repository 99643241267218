import {createRouter, createWebHistory,createWebHashHistory} from "vue-router";

const routes = [
    {
        name: 'login',
        path: '/',
        meta: {title: '用户登录'},
        component: () => import('@/views/Login.vue'),
    },
    {
        name: 'plus',
        path: '/chat',
        meta: {title: '普惠电力-智能助手'},
        component: () => import('@/views/ChatPlus.vue'),
    },
    {
        name: 'NotFound',
        path: '/:all(.*)',
        meta: {title: '页面没有找到'},
        component: () => import('@/views/404.vue'),
    },
]

// console.log(MY_VARIABLE)
const router = createRouter({
    history: createWebHashHistory(),//TODO 有#号 打包时
    //history: createWebHistory(),//开发时
    routes: routes,
})

// dynamic change the title when router change
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = `${to.meta.title}`
    }
    next()
})

export default router;